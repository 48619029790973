import { createRouter, createWebHistory } from 'vue-router'
import NotFoundPage from "@/components/NotFoundPage.vue";

const routes = [
  {
    path: '/',
    name: 'front',
    component: () => import(/* webpackChunkName: "get" */ '../components/FrontPage.vue')
  },
  {
    path: '/debtors',
    name: 'debtors',
    component: () => import(/* webpackChunkName: "debtors" */ '../components/debtors/DebtorsPage.vue')
  },
  {
    path: '/get',
    name: 'get',
    component: () => import(/* webpackChunkName: "debtors" */ '../components/get/GetPage.vue')
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import(/* webpackChunkName: "payments" */ '../components/payments/PaymentsPage.vue')
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFoundPage }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
