import { reactive } from "vue";
import store from "@/store"
import { RequestError } from "@/api/instance";

const useRequest = () => {
  const state = reactive({
    pending: false,
    success: false,
    error: false,
    message: '',
    errors: {}
  })

  return {
    state,

    async run (callback) {
      try {
        state.pending = true
        state.error = false
        state.success = false
        state.message = ''
        state.errors = {}
        await callback()
        state.success = true
      } catch (e) {
        state.error = true
        if (e instanceof RequestError) {
          if (e.status >= 500) {
            state.message = 'Серверная ошибка'
          } else {
            switch (e.status) {
              case 401:
                store.logout()
                break
              case 403:
                state.message = 'Действие запрещено'
                break
              case 404:
                state.message = 'Объект не найден'
                break
              case 422:
                state.errors = e.data
                break
              case 409:
                state.message = e.data
                break
              default:
                state.message = 'Неправильные данные'
            }
          }
        } else {
          throw e
        }

        state.error = true
      }
      state.pending = false
    }
  }
}

export default useRequest
