<template>
  <div class="login-form-wrapper">
    <h2>Вход в систему</h2>
    <el-form
      v-loading="formRequest.state.pending"
      ref="formRef"
      :model="model"
      :rules="rules"
      label-position="top"
      @submit.prevent="handleSubmit"
    >
      <el-form-item prop="username" label="Имя пользователя" :error="formRequest.state.errors.username">
        <el-input v-model="model.username" />
      </el-form-item>
      <el-form-item prop="password" label="Пароль" :error="formRequest.state.errors.password">
        <el-input v-model="model.password" type="password" />
      </el-form-item>
      <el-form-item>
        <el-button native-type="submit" type="success">Войти</el-button>
      </el-form-item>
    </el-form>
    <div v-if="formRequest.state.error">
      {{ formRequest.state.message }}
    </div>
  </div>
</template>

<script setup>
import {reactive, ref} from "vue"
import useRequest from "@/composables/useRequest"
import { login } from "@/api/auth"
import store from "@/store"

const model = reactive({
  username: '',
  password: ''
})

const rules = {
  username: [
    { required: true, trigger: 'blur', message: 'Поле обязательно' }
  ],
  password: [
    { required: true, trigger: 'blur', message: 'Поле обязательно' }
  ]
}

const formRequest = useRequest()

const formRef = ref()

const handleSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      formRequest.run(async () => {
        const response = await login(model)
        store.login(response.data)
      })
    }
  })
}

</script>

<style>
.login-form-wrapper {
  margin: 120px auto 0;
  max-width: 400px;
}
</style>
