import axios from "axios"

export class RequestError extends Error {
  constructor (status, data) {
    super();
    this.status = status
    this.data = data
  }
}

const config = {
  baseURL: 'https://erp3.podorojnik.ru/external-cash/',
  rejectUnauthorized: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  transformResponse: [(data) => {
    const response = JSON.parse(data)

    if (response.status >= 200 && response.status < 300) {
      return response.data
    }

    throw new RequestError(response.status, response.data)
  }]
}

export default axios.create(config)
