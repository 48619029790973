<template>
  <template v-if="store.state.user === undefined">
    <p v-if="request.state.pending">Загрузка...</p>
    <login-form v-else />
  </template>
  <el-container v-else>
    <el-header>
      {{ store.state.user.title }}
      <el-button link @click="handleLogout">Выйти</el-button>
    </el-header>
    <el-container>
      <el-aside>
        <side-bar-menu />
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import store from "@/store"
import LoginForm from "@/components/login/LoginForm.vue"
import SideBarMenu from "@/components/SideBarMenu.vue"
import useRequest from "@/composables/useRequest";
import {loadUser} from "@/api/auth";

const token = (new Map(document.cookie.split(';').map(i => i.split('=').map(v => v.trim())))).get('cToken')

const request = useRequest()
if (token) {
  request.run(async () => {
    const response = await loadUser(token)
    store.login(response.data)
  })
}

const handleLogout = () => {
  store.logout()
}
</script>
