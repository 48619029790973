import {reactive} from "vue";
import instance from "@/api/instance";

const state = reactive({
  user: undefined
})

export default {
  state,
  login (user) {
    state.user = user
    instance.defaults.headers.common['X-Api-Key'] = user.token
    document.cookie = `cToken=${user.token}`
  },
  logout() {
    state.user = undefined
    delete instance.defaults.headers.common['X-Access-Token']
    document.cookie = `cToken=`
  }
}
