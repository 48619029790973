import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "@/router"
import locale from 'element-plus/es/locale/lang/ru'

import 'dayjs/locale/ru.js'

createApp(App)
  .use(ElementPlus, { locale })
  .use(router)
  .mount('#app')
